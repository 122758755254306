import { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import logo from "../../images/karusel-logo.png";
import { useMutation, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../Home/LanguageSelect";

const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation activateUser($token: String!) {
    activateUser(token: $token) {
      id
    }
  }
`;

const ActivateAccount = () => {
  const { token } = useParams();
  const [activateUser] = useMutation(ACTIVATE_ACCOUNT_MUTATION);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  async function handleSubmit() {
    try {
      const r = await activateUser({
        variables: {
          token,
        },
      });

      if (r.errors) {
        setError(t("activateAccount.activateAccountErrorMessage"));
        return;
      }

      setSuccess(t("activateAccount.activateAccountSuccessMessage"));
      return;
    } catch {
      setError(t("activateAccount.activateAccountErrorMessage"));
      return;
    }
  }

  return (
    <>
      <Page>
        <LanguageBox>
          <LanguageSelect />
        </LanguageBox>
        <Modal>
          <ModalHeader>
            <Logo src={logo} alt="" width={"200"} />
          </ModalHeader>
          <ModalBody>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && <SuccessMessage>{success}</SuccessMessage>}
          </ModalBody>
          {!error && !success && (
            <Button type="button" onClick={handleSubmit}>
              {t("activateAccount.activateAccountButton")}
            </Button>
          )}
        </Modal>
      </Page>
    </>
  );
};

const LanguageBox = styled.div`
  margin-block-start: 2rem;
  margin-block-end: 15rem;
  margin-inline-end: 2rem;
  align-self: flex-end;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  background-color: #e8e2d4;
`;

const Modal = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  background-color: white;

  @media screen and (max-width: 600px) {
    padding-inline: 5px;
  }
`;

const ModalHeader = styled.div`
  text-align: center;
`;
const ModalBody = styled.div`
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 15rem;
`;

const SuccessMessage = styled.p`
  color: #087f5b;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Inter";
  max-width: 65%;
  text-align: center;
`;

const ErrorMessage = styled.p`
  color: #fa5252;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Inter";
  max-width: 65%;
  text-align: center;
`;

const Button = styled.button`
  color: white;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 700;
  border: none;
  padding: 20px;
  cursor: pointer;
  border-radius: 10px;

  background-color: #456b6e;

  &:hover {
    background-color: rgba(69, 107, 111, 0.8);
  }

  @media screen and (max-width: 600px) {
    margin-inline: 5px;
  }
`;

export default ActivateAccount;
