import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const Footer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      bgcolor="#E8E2D4"
      px={20}
      py={5}
      justifyContent="space-between"
      alignItems="center"
      direction={isSmallScreen ? "column" : "row"}
      color="#456b6e"
      gap={3}
      flexWrap={isSmallScreen ? "wrap" : "nowrap"}
      divider={
        <Divider
          variant="middle"
          orientation={isSmallScreen ? "horizontal" : "vertical"}
          flexItem
        />
      }
    >
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        alignItems="center"
        gap={isSmallScreen ? 0.5 : 2}
      >
        <Typography>{t("companyInfo.socialMedia")}</Typography>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              window.open("https://www.instagram.com/karusel_dk/", "_blank");
            }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              window.open(
                "https://www.facebook.com/profile.php?id=100090093165173",
                "_blank"
              );
            }}
          >
            <FacebookIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        {!isSmallScreen && <MailOutlinedIcon />}
        <Typography>info@karusel.dk</Typography>
      </Stack>
      <Stack direction="row" gap={1} lineHeight={1.3} alignItems="center">
        {!isSmallScreen && <LocationOnOutlinedIcon />}
        <Typography textAlign={isSmallScreen ? "center" : "initial"}>
          Strandvejen 227, 2 mf 2920 Charlottenlund
        </Typography>
      </Stack>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? 0.8 : 2}
        textAlign={isSmallScreen ? "center" : "initial"}
      >
        <Typography style={{ maxWidth: 200 }}>CVR.: 43811312</Typography>
        <Typography style={{ maxWidth: 200 }}>© 2023 Karusel ApS</Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
