import { gql, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import phone from "../../images/app-image.svg";
import InputField from "./InputField";
import SubmitButton from "./SubmitButton";
import * as yup from "yup";
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const CREATE_LEAD_MUTATION = gql`
  mutation createLead($input: LeadInput!) {
    createLead(input: $input) {
      id
    }
  }
`;

const validationSchema = yup.object().shape({
  userName: yup.string().required().min(3),
  userEmail: yup.string().email().required(),
});
type formValues = { userName: string; userEmail: string };

type SnackbarStateType = "success" | "error" | undefined;

const Main = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userName: "",
      userEmail: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [createLead] = useMutation(CREATE_LEAD_MUTATION);
  const [snackbarState, setSnackbarState] = useState<SnackbarStateType>();

  const saveLeadInfo = async (data: formValues) => {
    try {
      await createLead({
        variables: {
          input: {
            name: data.userName,
            email: data.userEmail,
          },
        },
      });
      setSnackbarState("success");
    } catch (err) {
      console.log(err);
      setSnackbarState("error");
    }
    reset();
  };

  const isSnackbarVisible = (): boolean => {
    if (snackbarState === "success" || snackbarState === "error") {
      return true;
    }
    return false;
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState(undefined);
  };

  return (
    <Box bgcolor="#E8E2D4" p={8}>
      <Stack
        direction="row"
        justifyContent="space-around"
        flexWrap={isSmallScreen ? "wrap" : "nowrap"}
        gap={10}
      >
        <Stack
          color="#456B6E"
          textAlign="justify"
          maxWidth={isSmallScreen ? "100%" : "45%"}
          paddingTop={isSmallScreen ? 6 : 10}
        >
          <Typography variant="h1" paddingBottom="3rem">
            {t("main.title")}
          </Typography>
          <Typography variant="body2" paddingBottom="5rem" width="85%">
            {t("main.description")}
          </Typography>
          <form
            method="post"
            onSubmit={handleSubmit(saveLeadInfo)}
            style={{
              display: "flex",
              gap: 15,
              flexDirection: "column",
            }}
          >
            <Controller
              name={"userName"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  onChange={onChange}
                  value={value}
                  label={t("main.formName")}
                />
              )}
            />
            <Controller
              name={"userEmail"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputField
                  onChange={onChange}
                  value={value}
                  label={t("main.formEmail")}
                />
              )}
            />
            <SubmitButton disabled={!isValid} />
          </form>
          <Divider style={{ marginBlock: 20 }} />
          <Typography>{t("main.informationText")}</Typography>
        </Stack>
        <img
          src={phone}
          alt=""
          style={{
            width: isSmallScreen ? 203 : 603,
            height: isSmallScreen ? 400 : 800,
          }}
        />
      </Stack>
      <Snackbar
        open={isSnackbarVisible()}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: isSmallScreen ? "bottom" : "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState}>
          <Typography variant="body1">
            {snackbarState === "success"
              ? t("main.snackBarSuccessMessage")
              : t("main.snackBarErrorMessage")}
          </Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Main;
