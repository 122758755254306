import { Route, Routes } from "react-router-dom";
import ActivateAccount from "./pages/ActivateAccount/ActivateAccount";
import Home from "./pages/Home/Home";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/activate-account/:token" element={<ActivateAccount />} />
    </Routes>
  );
};

export default App;
