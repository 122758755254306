import SystemUpdateOutlinedIcon from "@mui/icons-material/SystemUpdateOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import StepsCard from "./StepsCard";
import { ReactElement } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const StepsCardList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const CARD_LIST_ROW_ONE: {
    icon: ReactElement;
    title: string;
    description: string;
  }[] = [
    {
      icon: (
        <SystemUpdateOutlinedIcon
          fontSize="large"
          style={{ color: "#456b6e" }}
        />
      ),
      title: t("stepsSection.step1.title"),
      description: t("stepsSection.step1.description"),
    },
    {
      icon: (
        <Diversity1OutlinedIcon fontSize="large" style={{ color: "#456b6e" }} />
      ),
      title: t("stepsSection.step2.title"),
      description: t("stepsSection.step2.description"),
    },
    {
      icon: (
        <RequestQuoteOutlinedIcon
          fontSize="large"
          style={{ color: "#456b6e" }}
        />
      ),
      title: t("stepsSection.step3.title"),
      description: t("stepsSection.step3.description"),
    },
    {
      icon: (
        <BallotOutlinedIcon fontSize="large" style={{ color: "#456b6e" }} />
      ),
      title: t("stepsSection.step4.title"),
      description: t("stepsSection.step4.description"),
    },
    {
      icon: (
        <BeenhereOutlinedIcon fontSize="large" style={{ color: "#456b6e" }} />
      ),
      title: t("stepsSection.step5.title"),
      description: t("stepsSection.step5.description"),
    },
  ];

  return (
    <Stack
      spacing={6}
      direction={isSmallScreen ? "column" : "row"}
      justifyContent="center"
      flexWrap={isSmallScreen ? "wrap" : "nowrap"}
    >
      {CARD_LIST_ROW_ONE.map((card) => {
        return (
          <StepsCard
            title={card.title}
            description={card.description}
            icon={card.icon}
            key={card.title}
          />
        );
      })}
    </Stack>
  );
};

export default StepsCardList;
