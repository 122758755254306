import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "./i18n";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_KARUSEL_BACKEND_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

let theme = createTheme({
  typography: {
    h1: {
      fontSize: "4rem",
      fontWeight: "normal",
      fontFamily: "Playfair Display",
      letterSpacing: "-0.011em",
    },

    h2: {
      fontWeight: "normal",
      fontSize: "3rem",
      fontFamily: "Playfair Display",
      paddingBottom: "2rem",
      color: "#615656",
    },

    h5: {
      fontWeight: "normal",
      fontFamily: "Playfair Display",
      color: "#615656",
    },

    body1: {
      fontFamily: "Raleway",
      fontSize: 15,
      fontWeight: "normal",
      letterSpacing: "-0.011em",
      color: "#456b6e",
    },

    body2: {
      fontSize: "1.8rem",
      fontWeight: "normal",
      letterSpacing: "-0.006em",
      fontFamily: "Raleway",
    },

    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: "normal",
      fontFamily: "Raleway",
      textAlign: "justify",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {},
    },
  },
  palette: {
    success: {
      main: "#456B6E",
    },

    primary: {
      main: "#749195",
    },
  },
});

theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);
