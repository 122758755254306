import Image1 from "../../images/image1.png";
import Image2 from "../../images/image2.png";

import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactElement } from "react";

const Description = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Stack p={7} color="#4a4a4a">
      <DescriptionBlock
        title={t("description.block1.title")}
        description={t("description.block1.description")}
        image={
          <img
            height={isSmallScreen ? "300px" : "533px"}
            width={isSmallScreen ? "350px" : "800px"}
            src={Image1}
            alt=""
          />
        }
      />
      <DescriptionBlock
        title={t("description.block2.title")}
        description={t("description.block2.description")}
        image={
          <img
            height={isSmallScreen ? "400px" : "800px"}
            width={isSmallScreen ? "350px" : "533px"}
            src={Image2}
            alt=""
          />
        }
        direction="row-reverse"
      />
    </Stack>
  );
};

type DescriptionBlockProps = {
  title: string;
  description: string;
  image: ReactElement;
  direction?: "row" | "row-reverse";
};

const DescriptionBlock = ({
  title,
  description,
  image,
  direction = "row",
}: DescriptionBlockProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack
      direction={isSmallScreen ? undefined : direction}
      alignItems="center"
      justifyContent="space-between"
      gap={isSmallScreen ? 10 : 20}
      pb={6}
      pt={7}
      flexWrap={isSmallScreen ? "wrap" : "nowrap"}
    >
      <Stack>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle1">{description}</Typography>
      </Stack>
      <Typography>{image}</Typography>
    </Stack>
  );
};

export default Description;
