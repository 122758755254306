import { useState, ChangeEvent } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import logo from "../../images/karusel-logo.png";
import { useMutation, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../Home/LanguageSelect";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($token: String, $newPassword: String!) {
    changePassword(token: $token, newPassword: $newPassword) {
      token
    }
  }
`;

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    input: {
      password: "",
      confirmPassword: "",
    },
    error: "",
    success: "",
  });

  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFormData((prevData) => ({
      ...prevData,
      input: {
        ...prevData.input,
        [e.target.name]: e.target.value,
      },
    }));
  }

  async function handleSubmit() {
    const { password, confirmPassword } = formData.input;

    if (password === "" || confirmPassword === "") {
      setFormData((prevData) => ({
        ...prevData,
        error: t("resetPassword.passwordRequiredErrorMessage"),
      }));
      return;
    }

    if (password !== confirmPassword) {
      setFormData((prevData) => ({
        ...prevData,
        error: t("resetPassword.passwordDontMtchErrorMessage"),
      }));
      return;
    }

    try {
      await changePassword({
        variables: {
          token,
          newPassword: password,
        },
      });
      setFormData({
        input: {
          password: "",
          confirmPassword: "",
        },
        error: "",
        success: t("resetPassword.passwordSuccessMessage"),
      });
      setIsPasswordUpdated(true);

      return;
    } catch {
      setFormData((prevData) => ({
        ...prevData,
        error: t("resetPassword.tokenExpiredErrorMessage"),
        success: "",
      }));
      return;
    }
  }

  return (
    <Page>
      <LanguageBox>
        <LanguageSelect />
      </LanguageBox>
      <Modal>
        <ModalHeader>
          <Logo src={logo} alt="" width={"200"} />
          {formData.error !== "" && (
            <ErrorMessage>{formData.error}</ErrorMessage>
          )}
          {formData.success !== "" && (
            <SuccessMessage>{formData.success}</SuccessMessage>
          )}
        </ModalHeader>
        {!isPasswordUpdated && (
          <>
            <Label>{t("resetPassword.password")}</Label>
            <Input
              type="password"
              name="password"
              value={formData.input.password}
              onChange={handleChange}
            />
            <Label>{t("resetPassword.confirmPassword")}</Label>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.input.confirmPassword}
              onChange={handleChange}
            />
            <Button type="button" onClick={handleSubmit}>
              {t("resetPassword.resetPasswordButton")}
            </Button>
          </>
        )}
      </Modal>
    </Page>
  );
};

export default ResetPassword;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  gap: 6rem;
  background-color: #e8e2d4;
`;

const LanguageBox = styled.div`
  margin-block-start: 2rem;
  margin-inline-end: 2rem;
  align-self: flex-end;
`;

const Modal = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 15rem;
`;

const Label = styled.label`
  color: #495057;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Inter";
`;

const SuccessMessage = styled.p`
  color: #087f5b;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Inter";
  margin-block-start: 6rem;
  text-align: center;
`;

const ErrorMessage = styled.p`
  color: #fa5252;
  margin-top: 20px;
  font-size: 18px;
  font-family: "Inter";
  text-align: center;
`;

const Input = styled.input`
  height: 30px;
  border: 1px solid #eee;
  border-radius: 10px;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  color: white;
  margin-top: 20px;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #456b6e;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 700;

  &:hover {
    background-color: rgba(69, 107, 111, 0.8);
  }
`;
