import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import TranslationEnglish from "./translation/english/translation.json";
import TranslationDanish from "./translation/danish/translation.json";

export enum SupportedLanguages {
  English = "en",
  Danish = "dk",
}

const resources = {
  [SupportedLanguages.Danish]: {
    translation: TranslationDanish,
  },
  [SupportedLanguages.English]: {
    translation: TranslationEnglish,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: SupportedLanguages.Danish,
  supportedLngs: [SupportedLanguages.Danish, SupportedLanguages.English],
  fallbackLng: SupportedLanguages.Danish,
});

export default i18next;
