import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { useState, useEffect } from "react";

const FloatingButton = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.scrollY > 400
        ? setIsButtonVisible(true)
        : setIsButtonVisible(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Stack bgcolor="#E8E2D4">
      {isButtonVisible && (
        <Stack position="fixed" bottom={30} right={30}>
          <IconButton
            aria-label="scroll-up"
            onClick={handleScrollToTop}
            style={{ backgroundColor: "#b8d4d7" }}
          >
            <ExpandLessOutlinedIcon
              fontSize="medium"
              style={{ color: "#456b6e" }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default FloatingButton;
