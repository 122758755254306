import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";

type StepsCardProps = {
  icon: ReactElement;
  title: string;
  description: string;
};

const StepsCard = ({ icon, title, description }: StepsCardProps) => {
  return (
    <Card
      sx={{
        minWidth: 150,
        minHeight: 150,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        boxShadow: 2,
      }}
    >
      <CardContent>
        {icon}
        <Typography variant="h5" sx={{ pb: 1.5 }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          textAlign="left"
          fontWeight={300}
          maxWidth={200}
          color="#456b6e"
          fontFamily="Raleway"
          sx={{ textAlign: "left", fontWeight: 200, color: "#456b6e" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StepsCard;
