import { Stack, Typography } from "@mui/material";
import StepsCardList from "./StepsCardList";
import { useTranslation } from "react-i18next";

const StepsSection = () => {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={8}
      justifyContent="center"
      alignItems="center"
      padding="4rem"
    >
      <Typography variant="h1" color="#615656">
        {t("stepsSection.title")}
      </Typography>
      <StepsCardList />
    </Stack>
  );
};

export default StepsSection;
