import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { SupportedLanguages } from "../../i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(SupportedLanguages.Danish);

  const changeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as SupportedLanguages);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: 100,
      }}
    >
      <FormControl fullWidth>
        <InputLabel>{t("navbar.language")}</InputLabel>
        <Select
          value={language}
          label={t("navbar.language")}
          onChange={changeLanguage}
          color="success"
        >
          <MenuItem value={SupportedLanguages.English}>
            {t("navbar.selectEn")}
          </MenuItem>

          <MenuItem value={SupportedLanguages.Danish}>
            {t("navbar.selectDk")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelect;
