import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

type SubmitButtonProp = {
  disabled: boolean;
};

const SubmitButton = ({ disabled }: SubmitButtonProp) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Button
        color="success"
        variant="contained"
        size="large"
        type="submit"
        disabled={disabled}
      >
        <Typography color={disabled ? "gray" : "white"}>
          {t("main.submitButton")}
        </Typography>
      </Button>
    </Box>
  );
};

export default SubmitButton;
