import { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

type InputFieldProp = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const InputField = ({ label, onChange, value }: InputFieldProp) => {
  return (
    <Box
      component="form"
      bgcolor="white"
      borderColor="darkorange"
      borderRadius="4px"
      sx={{
        "& > :not(style)": {
          width: "100%",
        },
      }}
    >
      <TextField
        color="success"
        label={label}
        onChange={onChange}
        value={value}
        variant="filled"
      />
    </Box>
  );
};

export default InputField;
