import Navbar from "./Navbar";
import Main from "./Main";
import Description from "./Description";
import StepsSection from "./StepsSection";
import Footer from "./Footer";
import FloatingButton from "./FloatingButton";
import { Box } from "@mui/material";

function Home() {
  return (
    <Box>
      <Navbar />
      <Main />
      <StepsSection />
      <Description />
      <Footer />
      <FloatingButton />
    </Box>
  );
}

export default Home;
