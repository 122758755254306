import logo from "../../images/karusel-logo.png";
import { Stack } from "@mui/system";
import { useMediaQuery, useTheme } from "@mui/material";
import LanguageSelect from "./LanguageSelect";

const Navbar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={isSmallScreen ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      height="7rem"
      bgcolor="#E8E2D4"
      px="2.5rem"
      py="2rem"
      gap={5}
    >
      <img src={logo} alt="" width={isSmallScreen ? "150" : "200"} />
      <LanguageSelect />
    </Stack>
  );
};

export default Navbar;
